import React from "react";
import cookies from "react-cookies";
import { useDispatch } from "react-redux";

import DapAPI from "api/dap";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { BrowserRouter } from "react-router-dom";

import { SnackbarProvider } from "notistack";

import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { ConfirmationDialogProvider } from "shared/ui/ConfirmationDialog";

import themeLight from "./styles/themes/themeLight.json";

import "./styles/index.css";
import AppRouter from "./Router";

const LIGHT_THEME = createTheme(themeLight);

const App = () => {
  const dispatch = useDispatch();

  React.useLayoutEffect(() => {
    const version = process.env.REACT_APP_VERSION;
    const storageVersion = localStorage.getItem("version");
    if (version === storageVersion) return;

    const cookiesToRemove = ["theme", "locale", "login", "password", "sessionToken"];
    cookiesToRemove.forEach((key) => cookies.remove(key, { path: process.env.PUBLIC_URL + "/" }));

    for (let index = 0; index < localStorage.length; index++) {
      localStorage.removeItem(localStorage.key(index));
    }
    localStorage.setItem("version", version);
  }, []);

  React.useEffect(() => {
    const dapApi = new DapAPI({
      server: process.env.REACT_APP_API || "https://av.contourbi.ru/api",
      errorText: "message",
      dispatch,
    });
    dispatch({ type: "SET_API", value: dapApi });
  }, [dispatch]);

  return (
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={LIGHT_THEME}>
          <CssBaseline />
          <DndProvider backend={HTML5Backend}>
            <SnackbarProvider maxSnack={3} autoHideDuration={10000} anchorOrigin={{ horizontal: "left", vertical: "bottom" }}>
              <ConfirmationDialogProvider>
                <AppRouter />
              </ConfirmationDialogProvider>
            </SnackbarProvider>
          </DndProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  );
};

export default App;
