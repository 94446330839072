/* eslint-disable react-hooks/exhaustive-deps */

import React, { useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useSelector } from "react-redux";

import FilterList from "./FilterList";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import Popover from "@mui/material/Popover";

import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { withStyles } from "@mui/styles";

import { Icon } from "./../../../shared/ui/ToolBar";
import { projectItemImageComponent } from "../../../shared/utils";

import { ReactComponent as FilterIcon } from "@mdi/svg/svg/filter-variant.svg";
import BitArray from "shared/utils/BitArray";
import { DUPLICATE_INDENTIFICATOR } from "shared/ui/TableEditor";

function dimCardFilterValue(dim) {
  if (!dim.properties.withLabel) return "";

  const filter = dim.properties.filter;
  if (!Array.isArray(filter) || !Array.isArray(dim.dict) || filter.length === 0) return "";

  const values = [];
  const bitArray = new BitArray(dim.dict.length);
  bitArray.values = filter;
  bitArray.not();

  dim.dict.forEach(dict => {
    if (!bitArray.get(dict.index)) return "";
    values.push(dict)
  })
  
  if (values.length === 0) return "";

  const firstValue = values[0];
  return ` — ${firstValue.name}`;
}

const StyledPaper = withStyles(
  (theme) => ({
    root: {
      display: "flex",
      border: `1px solid ${theme.palette.divider}`,
      /*
    ${theme.palette.type === 'light'
        ? 'rgba(0, 0, 0, 0.25)'
        : 'rgba(255, 255, 255, 0.75)'
      }`,
*/
      alignItems: "center",
      backgroundColor: "transparent",
      cursor: "pointer",
      transition: "background .15s ease-in-out",
      "&:hover": {
        backgroundColor: "#EDF1F2 !important"
      }
    },
  }),
  { withTheme: true }
)(Paper);

const useStyles = makeStyles((theme) => ({
  container: {
    overflowX: "hidden",
    display: "flex",
    cursor: "grabbing"
  },
  container_full: {
    overflowX: "hidden",
    display: "flex",
    width: "100%",
    cursor: "grabbing",
    padding: 0,
  },
  details: {
    display: "flex",
    userSelect: "none",
    WebkitUserSelect: "none",
    flexDirection: "column",
    justifyContent: "center",
    flexGrow: 1,
    padding: 0,
    overflowX: "hidden",
  },
}));

export const PopoverCustom = (props) => {
  const { Component, trigger } = props;
  return (
    <PopupState variant="popover" popupId={props.id + "popup"}>
      {(popupState) => (
        <React.Fragment>
          <span
            {...bindTrigger(popupState)}
            style={{ flexGrow: 1, overflowX: "hidden" }}
          >
            {trigger}
          </span>
          <Popover
            {...bindPopover(popupState)}
            PaperProps={{ style: { overflowY: "hidden" } }}
            autoFocus={true}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            marginThreshold={60}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Component popupState={popupState} {...props} />
          </Popover>
        </React.Fragment>
      )}
    </PopupState>
  );
};

export const DimButton = (props) => {
  const ref = useRef();
  const style = { ...props.style } || {};
  const id = props.id;
  const theme = useTheme();
  const [hoverId, setHoverId] = useState({ id: null });
  const current = useSelector((state) => state.current);
  const api = useSelector((state) => state.API);

  style.backgroundColor === undefined &&
    (style.backgroundColor = "#F7F9FA");

  const [, drop] = useDrop({
    accept: "dim",
    collect(monitor) {
      return {
        isOver: monitor.isOver(),
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.id;
      const hoverIndex = props.id;
      const hoverDim = props.dim;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        // сбрасываем если был
        if (item.hoverDim) {
          item.hoverIndex = null;
          item.hoverDim = null;
          item.hoverPos = null;
          props.onHover && props.onHover(null, null);
        }
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;
      const hoverPos = hoverClientX < hoverMiddleX;

      if (item.hoverIndex === hoverIndex && item.hoverPos === hoverPos) {
        return;
      }

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.hoverIndex = hoverIndex;
      item.hoverDim = hoverDim;
      item.hoverPos = hoverPos;

      if (!monitor.canDrop()) {
        return;
      }
      props.onHover && props.onHover(hoverIndex, hoverPos);
    },
    canDrop(item, monitor) {
      if (item.hoverDim?.properties.axis === "static") return false;
      if (
        item.dim?.properties.isLang === true &&
        !(
          item.hoverDim?.properties.axis === "data" ||
          item.hoverDim?.properties.axis === "fixed"
        )
      )
        return false;

      return true;
    },
    drop(self, monitor) {
      if (monitor.didDrop()) return;

      if (self.hiddenColumn) {
        self.hiddenColumn.properties.axis = self.hoverDim.properties.axis;
        props.onDropHiddenColumn?.({ dim: self.hiddenColumn, hoverId: self.hoverDim.id, pre: self.hoverPos });
        return;
      }

      props.onDrop && props.onDrop(self.dim, self.hoverDim, self.hoverPos);
      return { moved: true };
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "dim",
    item: () => {
      console.log("START DRAG:", id);
      props.onStartDrag && props.onStartDrag(id);
      return { id, dim: props.dim };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: (monitor) => {
      return props.dim.properties.axis !== "static";
    },
    end: (item, monitor) => {
      props.onDrop && props.onDrop();
    },
  });

  drag(drop(ref));
  // для самостоятельной перерисовки в клетки грида
  props.updatabled && (props.updatabled[id] = { id, hoverId, setHoverId });

  const lc = isDragging
    ? "#007bff"
    : hoverId.id === id && hoverId.pos === true
    ? "green"
    : "transparent";
  const rc = isDragging
    ? "#007bff"
    : hoverId.id === id && hoverId.pos === false
    ? "green"
    : "transparent";

  const handleDragStart = (event) => {
    const borderRadius = event.target.style.borderRadius;
    const borderColor = event.target.style.borderColor;
    event.target.style.borderRadius = 0;
    event.target.style.borderColor = "#007bff";
    requestAnimationFrame(() => {
      if (event?.target?.style) {
        event.target.style.borderRadius = borderRadius;
        event.target.style.borderColor = borderColor;
      }
    });
  };

  if (props.isDragState) style.borderColor = "#fd7e14";

  const order =
    props.allowChangeSortOrder === true && props.dim.properties.sortOrder;
  const colorDown =
    order === "DESC" ? theme.palette.action.active : "transparent";
  const colorUp = order === "ASC" ? theme.palette.action.active : "transparent";
  const hasFilter =
    (
      Array.isArray(props.dim.properties.filter) && 
      props.dim.properties.filter.length > 0 &&
      !props.dim.properties.filter.includes(-1)  
    ) || (
      props.dim.properties.textFilter?.operator &&
      props.dim.properties.textFilter.operator !== "ANY"
    );
  const upDownSize = "0.32em";

  const classes = useStyles();

  const title = props.dim.properties.title + dimCardFilterValue(props.dim);
  const tooltip = `${title} (${props.dim.properties.name.split(DUPLICATE_INDENTIFICATOR)[0]})`;

  const Context = (
    <div
      style={{
        display: "flex",
        flexGrow: 1,
        alignSelf: "stretch",
        overflowX: "hidden",
      }}
    >
      <div
        style={{
          display: "flex",
          flexGrow: 1,
          padding: "0.2em",
          alignItems: "center",
          width: "100%",
        }}
      >
        {props.icon
          ? Icon(hasFilter ? "primary" : "action", props.icon, "24px")
          : projectItemImageComponent(
              props.dim,
              "24px",
              "0 0.5em 0 0",
              theme.palette.menu.icon
            )}
        <div className={classes.details}>
          {props.dim.properties.shortTitle ? (
            <div
              style={{
                display: "-webkit-box",
                WebkitLineClamp: "4",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textAlign: "center",
                color: "#7B868C",
                fontWeight: 500,
                maxHeight: 60,
                minHeight: 24,
              }}
            >
              {props.dim.properties.title}
            </div>
          ) : (
            <span
              style={{
                display: "flex",
                justifyContent: !!props.dim.properties.additionalContent ? "space-between" : "center",
                alignItems: "center",
                textAlign: "center",
                fontStyle: hasFilter && "italic",
                color: "#7B868C",
                marginLeft: props.dim.properties.additionalContent ? "0.25em" : 0,
                minHeight: 24,
              }}
            >
              <div title={tooltip} style={{
                display: "-webkit-box",
                WebkitLineClamp: "3",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
                lineHeight: "15px",
                fontWeight: 500,
              }}>
                {title}
              </div>
              {props.dim.properties.additionalContent?.()}
            </span>
          )}
        </div>
      </div>
    </div>
  );

  const ContextWithUpDownSort = (
    <div
      style={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
      }}
    >
      {order && (
        <div
          style={{
            width: 0,
            height: 0,
            borderLeft: `${upDownSize} solid transparent`,
            borderRight: `${upDownSize} solid transparent`,
            borderTop: `${upDownSize} solid ${colorDown}`,
          }}
        />
      )}
      {Context}
      {order && (
        <div
          style={{
            width: 0,
            height: 0,
            borderLeft: `${upDownSize} solid transparent`,
            borderRight: `${upDownSize} solid transparent`,
            borderBottom: `${upDownSize} solid ${colorUp}`,
          }}
        />
      )}
    </div>
  );

  const ContextWithDragHoverIndicators = (
    <div style={{ display: "flex", width: "100%", flexDirection: "row" }}>
      <div
        style={{
          width: "3px",
          height: "100%",
          backgroundColor: lc,
          flexShrink: 0,
        }}
      />
      <PopoverCustom
        {...props}
        trigger={ContextWithUpDownSort}
        Component={FilterList}
        onChangeOrder={props.onChangeOrder?.bind(null, props.dim)}
        onChangeFixed={props.onChangeFixed?.bind(null, props.dim)}
        onChangeFilterAxis={props.onChangeFilterAxis?.bind(null, props.dim)}
        current={current}
        requestApi={api}
        text={"Filter"}
        icon={FilterIcon}
      />
      <div
        style={{
          width: "3px",
          height: "100%",
          backgroundColor: rc,
          flexShrink: 0,
        }}
      />
    </div>
  );

  return (
    <StyledPaper
      ref={ref}
      onDragStart={handleDragStart}
      elevation={0}
      square={props.square === true}
      key={id}
      id={id}
      style={{ ...style, maxWidth: props.filterArea ? "30%" : "unset", zIndex: isDragging ? 2 : 1, alignItems: "stretch" }}
    >
      {ContextWithDragHoverIndicators}
    </StyledPaper>
  );
};
