import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { fSelect, fText } from "shared/ui/Editors";

const TYPE_FIELD_OPTIONS = [
  { id: "Reference", name: "Справочник" },
  { id: "ParentChildClassifier", name: "Классификатор родитель-дитя" },
];

function ObjectDWH({ value, code, required, title = "Объект хранилища", onChange }) {
  const api = useSelector((state) => state.API);

  const [data, setData] = React.useState(() => {
    if (value[code + "__type"] && value[code + "__code"]) return {
      code: value[code + "__code"],
      name: null,
      type: value[code + "__type"],
    }

    return {
      code: null,
      name: null,
      type: "Reference",
    }
  });
  const [references, setReferences] = React.useState({});

  React.useEffect(() => {
    if (!api || references[data.type]) return;
    api.send(`dwh/list/${data.type.toLowerCase()}`).then((res) => {
      if (typeof res !== "object" || res === null || res.error) return;
      const elements = res.map(({ code, caption }) => ({ code, caption: `${caption} (${code})` }));
      setReferences((references) => ({ ...references, [data.type]: elements }));

      if (!value[code + "__code"]) return;
      const foundReference = elements.find((reference) => reference.code === value[code + "__code"]);
      if (foundReference) setData((data) => ({ ...data, name: foundReference.caption }));
    });
  }, [api, data]);

  const handleChange = React.useCallback(
    (key, fieldValue) =>
      setData((data) => {
        const newData = { ...data };
        newData[key] = fieldValue;

        if (key === "type") {
          newData.code = null;
          newData.name = null;
        }

        if (key === "name") {
          newData.code = fieldValue.code;
          newData.name = fieldValue;
        }

        value[code + "__type"] = newData.type;
        value[code + "__code"] = newData.code;
        onChange();

        return newData;
      }),
    [code, onChange, value]
  );

  const inputProps = {
    disabled: false,
    fullWidth: true,
    style: "compact",
    onChange: (key, __, value) => handleChange(key, value),
    value: data,
  };

  return (
    <Container>
      <FieldsContainer>
        {fSelect(inputProps, "Тип объекта", "type", null, { select: TYPE_FIELD_OPTIONS, onlyIdInValue: true, disableClean: true })}
        <RowFieldsContainer>
          {fText(inputProps, "Код объекта", "code", null, { disabled: true })}
          {fSelect(inputProps, "Название объекта", "name", null, {
            select: references[data.type] || [],
            optionNameField: "caption",
            optionIdField: "code",
            disableClean: true,
          })}
        </RowFieldsContainer>
      </FieldsContainer>
      <Fieldset>
        <Legend>{title} {required && <Required>*</Required>}</Legend>
      </Fieldset>
    </Container>
  );
}

const Container = styled("div")`
  margin: 0.1em 0.25em;
  position: relative;
  display: flex;
`;

const FieldsContainer = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 16px 8px;
  z-index: 2;
  background: transparent;
`;

const Required = styled("span")`
  color: #ff5c5c;
`;

const RowFieldsContainer = styled("div")`
  display: grid;
  grid-template-columns: 30% 70%;
`;

const Fieldset = styled("fieldset")`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  margin: 0;
  bottom: 0.5em;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
`;

const Legend = styled("legend")`
  padding: 0 4px;
  font-size: 0.85em;
  color: rgba(0, 0, 0, 0.6);
`;

export default React.memo(ObjectDWH);
