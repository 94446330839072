import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useMemo,
} from "react";
import { createPortal } from "react-dom";
import { withTheme, makeStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { isNil } from "lodash";
import clsx from "clsx";
import { useTheme } from "@mui/material/styles";

import dayjs from "dayjs";
import "dayjs/locale/ru";
import "dayjs/locale/es";
import "dayjs/locale/ar";

import { cellsRenderer } from "./core/cells";
import { geometry, geometry_rh } from "./core/geometry";

import { editorComponent } from "components/itemDialog/components";

import { Icon, TooltipR, ToolButton } from "shared/ui/ToolBar";
import { ReactComponent as IconCheckBoxOn } from "@mdi/svg/svg/checkbox-marked-outline.svg";
import { ReactComponent as IconCheckBoxOff } from "@mdi/svg/svg/checkbox-blank-outline.svg";
import { ReactComponent as IconRowNumber } from "@mdi/svg/svg/format-list-bulleted.svg";
import { ReactComponent as IconRowExpand } from "@mdi/svg/svg/chevron-right.svg";
import { ReactComponent as IconRowCollapse } from "@mdi/svg/svg/chevron-down.svg";
import { ReactComponent as DownloadIcon } from "@mdi/svg/svg/download.svg";
import styled from "styled-components";
import { ContourBackdrop } from "shared/ui";

const useStyles = makeStyles((theme) => ({
  common_edit: {
    alignItems: "center",
    display: "flex",
    padding: "0.2em 0.5em",
    boxSizing: "border-box",
    border: `2px ${theme.palette.divider} solid`,
    boxShadow: `0 2px 6px 2px rgb(60 64 67 / 15%)`,
    zIndex: 10,
    "& .MuiInput-underline:before": {
      border: "0 !important",
    },
  },
  common: {
    display: "flex",
    padding: "calc(1px + 0.2em) calc(1px + 0.5em)", // 1px для рамки в 2px при редактирование
    boxSizing: "border-box",
    border: `1px solid #DCE3E5`,
    color: "#42474D"
  },
  selectable: {
    cursor: "pointer",
  },
  haxis: {
    backgroundColor: "#F7F9FA",
    border: `1px solid DCE3E5`,
    transition: "background .15s ease-in-out",
    "& .MuiPaper-root": {
      borderRadius: 0
    },
    "&:hover": {
      backgroundColor: "#EDF1F2 !important"
    }
  },
  vaxis: {
    transition: "background .15s ease-in-out",
    "&:hover": {
      backgroundColor: "#EDF1F2 !important"
    }
  },
  data: {
    backgroundColor: theme.palette.background.paper,
  },
  hdiv: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  vdiv: {
    height: "100%",
    width: "100%",
    display: "flex",
    textAlign: "end",
    flexDirection: "column",
    justifyContent: "center",
  },
  idiv: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "top",
  },
  ispan: {
    overflow: "hidden",
  },
  ispanData: {
    whiteSpace: "nowrap",
  },
  ispanSmall: {
    fontSize: "1em",
    fontWeight: "300",
    whiteSpace: "nowrap",
  },
  ispanBig: {
    fontSize: "1em",
    fontWeight: "500",
  },
  isspanWithIcon: {
    marginLeft: "0.2em",
  },
  ispanRight: {
    display: "flex",
    justifyContent: "flex-end",
  },
  resizeColumn: {
    display: "flex",
    zIndex: 100,
    background: "transparent !important"
  },
  resizeHandler: {
    flexGrow: 1,
    backgroundColor: "transparent",
    cursor: "ew-resize",
  },
}));

const ScrollArea = ({ classes, recalculate, data, topLeft, visible, cellRenderer, forseRecalculate }) => {
  const [tableRef, setTableRef] = useState(null);

  const m = useMemo(
    () => cellsRenderer(null, classes, data, topLeft, visible, cellRenderer),
    [cellRenderer, classes, data, topLeft, visible, forseRecalculate]
  );

  useEffect(() => {
    if (!tableRef) return;
    const observer = new IntersectionObserver((elements) => {
      if (elements.length < 1 || !elements[0].isIntersecting) return;
      const [firstElement] = tableRef.getElementsByClassName("select_all_handler");
      if (!firstElement || firstElement.offsetWidth > 20) return;
      recalculate();
    });
    observer.observe(tableRef);
    return () => observer.disconnect(tableRef);
  }, [tableRef, recalculate]);

  if (!data) return null;

  return (
    <div ref={setTableRef} style={{ width: 0, height: 0, top: 0, left: 0, position: "sticky" }}>
      {m}
    </div>
  );
};

const EditorContent = React.memo(
  ({ ndx, classes, keys, style, bindData, editorData, setEditorData }) => {
    const theme = useTheme();
      const api = useSelector((state) => state.API);

    const [value, setValue] = useState({
      value: editorData.data.rows[editorData.row][editorData.col],
    });
    const [loading, setLoading] = useState(false);

    const valueRef = useRef(value.value);

    const column = editorData.data.columns[editorData.col].data;
    const type = column?.properties.type;
    
    const [i, setI] = useState(() => {
      const isDictionary = column.id === "correspondence_table_grid_source_value" || column.id === "correspondence_table_grid_destination_value";
      const iType = isDictionary ? "Dictionary" : type === "number" ? "text" : type

      return {
        value: "value",
        type: iType,
        tooltip: null,
        data: { lines: null, onlyIdInValue: true, disableClearable: false, select: column.dict || [] },
      }
    })

    React.useEffect(() => {
      if (!column.id.startsWith("correspondence_table")) return;
      setLoading(true);
      const correspondenceCodeIndex = editorData.data.columns.findIndex((column) => column.name === "correspondence_code");
      const correspondence_key = editorData.data.rows[editorData.row][correspondenceCodeIndex];

      api.send("registries/suggest", { field: column.properties.name, correspondence_key, filter: [] }).then((res) => {
        setLoading(false);
        if (!Array.isArray(res)) return;
        const select = res.map((item) => ({ name: `${item.value} (${item.code})`, id: item.code }));
        setI({ value: "value", type: "Dictionary", tooltip: null, data: { disableClearable: false, onlyIdInValue: true, select } })
      });
    }, [column]);

    React.useEffect(() => () => editorData?.editorOk?.(valueRef.current), [editorData]);

    const handleChange_v2 = React.useCallback((object, v, event, val) => {
      const temp = val !== undefined ? val : event.target.value;
      const value = temp === undefined ? null : temp;
      valueRef.current = value;
      setValue({ value });
    }, []);

    const handleOnEnter = React.useCallback(() => {
      editorData.editorOk && editorData.editorOk(valueRef.current);
      valueRef.current = null;
      setEditorData?.(null);
      return false;
    }, [editorData, setEditorData]);

    const handleOnCancel = React.useCallback(() => {
      valueRef.current = null;
      editorData.editorCancel && editorData.editorCancel();
    }, [editorData]);

    const inputProps = React.useMemo(
      () => ({
        inputProps: {
          style: {
            padding: "0.25em",
          },
        },
        style: {
          padding: "0.5em 0.25em",
        },
        variant: "standard",
        fullWidth: true,
        size: "small",
        inCell: true,
      }),
      []
    );

    const dataProps = React.useMemo(
      () => ({
        disabled: false,
        onChange_v2: handleChange_v2,
        onEnter: handleOnEnter,
        onCancel: handleOnCancel,
        value,
        style: "compact",
      }),
      [handleChange_v2, handleOnCancel, handleOnEnter, value]
    );

    return (
      <div
        className={clsx(classes.common_edit, classes.data)}
        {...bindData}
        id={keys}
        key={keys}
        style={{ ...style, ...{ height: "100%", overflow: "auto" } }}
      >
        {loading && createPortal(<ContourBackdrop zIndex={1000} />, document.getElementById("root"))}
        {editorData.customEditRenderer
          ? editorData.customEditRenderer(
              { i, ndx, inputProps, dataProps, handleChange_v2, value, theme },
              editorData.rowId
            )
          : editorComponent(
              i,
              ndx,
              inputProps,
              dataProps,
              handleChange_v2,
              null,
              value,
              null,
              null,
              theme
            )}
      </div>
    );
  }
);

const CellContent = React.memo(
  ({
    tableState,
    classes,
    keys,
    style,
    cell,
    head,
    rowIndex,
    bindData,
    fixed,
  }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const locale = useSelector((state) => state.locale);
    const [background, setBackground] = useState(undefined);

    const handleLinkClick = React.useCallback((link) => {
      dispatch({ type: "SET_CURRENT", value: { current: { 
        id: link,
        properties: {title: "Панель", hint: null, icon: null},
        type: "dashboard"
      } } })
    }, [dispatch]);

    const data = React.useMemo(() => {
      if (head?.data?.properties.customCellRenderer) {
        return (
          head?.data.properties.customCellRenderer(
            cell,
            rowIndex,
            Object.fromEntries(
              tableState.columns.map(({ name }, i) => [
                name,
                tableState.rows[rowIndex][i],
              ])
            ),
            head,
            setBackground
          )
        );
      }
      if (head?.data?.properties.type === "ObjectArray") {
        let array = null;
        try {
          const parsedArray = JSON.parse(cell);
          if (Array.isArray(parsedArray)) array = parsedArray;
        } catch (error) {}
        if (!array) return null;
        return array.map(({ value }, index) => <span>{value}{index !== array.length - 1 && ","}</span>);
      }
      if (head?.data?.properties.type === "IFrameLink") return (
        <StyledLink onClick={() => handleLinkClick(cell)}>
          {cell}
        </StyledLink>
      );
      if (head?.data?.properties.type === "Boolean") {
        const value = typeof cell === "boolean" ? cell : Boolean(+cell);
        return value ? "Да" : "Нет";
      }
      if (head?.data?.properties.type === "Status") {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <div
              style={{
                display: "flex",
                width: 24,
                height: 24,
                borderRadius: "50%",
                background: Boolean(cell) ? "#B5F5CF" : "#E6E8ED"
              }}
            />
          </div>
        );
      }
      if (head?.data?.properties.type === "Interval") {
        return cell
          ? `${cell.hours || 0}:${cell.minutes || 0}:${cell.seconds || 0}`
          : "";
      }
      if (head?.data?.properties.type === "DateTime") {
        return cell ? dayjs(cell).locale(locale).format("DD.MM.YYYY HH:mm") : "";
      }
      if (head?.data?.properties.type === "Download") {
        const download = () => {
          const aTag = document.createElement("a");
          aTag.href = cell;
          aTag.download = true;
          document.body.appendChild(aTag);
          aTag.click();
          document.body.removeChild(aTag);
        }
        return ToolButton(DownloadIcon, download, !!cell, null, "reset_filters", null, "Скачать");
      }
      if (head?.data?.properties.type === "Date") {
        return cell ? dayjs(cell).locale(locale).format("DD.MM.YYYY") : "";
      }
      if (head?.data?.properties.type === "Image") {
        return cell ? (
          <img
            style={{ objectFit: "contain", width: "28px", height: "28px" }}
            src={cell}
            alt={cell}
          />
        ) : (
          <div style={{ display: "flex", width: "28px", height: "28px" }} />
        );
      }
      if (head?.dataTypeID === 1082) {
        return dayjs(cell).locale(locale).format("LL");
      }

      return !isNil(cell)
        ? cell instanceof Object === true
          ? cell.toString()
          : cell
        : "";
    }, [cell, head, locale, rowIndex, tableState, theme]);

    const alignRight = React.useMemo(() => {
      if (head?.data?.properties.type === "DateTime") return true;
      switch (head?.dataTypeID) {
        case 1082:
        case 20:
        case 21:
        case 23:
        case 700:
          return true;
        default:
          return false;
      }
    }, [head]);

    const content = React.useMemo(
      () => {
        const isSelected = (tableState.selectedRows || []).includes(tableState.rows[rowIndex][tableState.columnsIndex.id]);
        return (
          <div
            className={clsx(classes.common, fixed ? classes.vaxis : classes.data)}
            {...bindData}
            id={keys}
            key={keys}
            style={{ ...(style || {}), background, ...(head?.data?.styles || {}) }}
            title={typeof data === "string" && data}
          >
            <div className={classes.idiv}>
              <span
                className={alignRight ? classes.ispanRight : classes.ispan}
                style={{ 
                  display: "-webkit-box",
                  WebkitLineClamp: isSelected ? "unset" : "2",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {data}
              </span>
            </div>
          </div>
        )
      },
      [alignRight, bindData, tableState, rowIndex, classes, data, fixed, keys, style]
    );

    const tooltipMessage = head?.data?.properties?.tooltip;
    return tooltipMessage ? (
      <TooltipR text={tooltipMessage}>{content}</TooltipR>
    ) : (
      content
    );
  }
);

const CellContentCheckBoxHeader = React.memo(
  ({ classes, keys, style, bindData }) => (
    <TooltipR text={<span>Выбрать все</span>} placement="bottom">
      <div
        className={clsx(
          classes.common,
          classes.selectable,
          classes.haxis,
          "select_all_handler"
        )}
        {...bindData}
        id={keys}
        key={keys}
        style={{ ...(style || {}), borderLeft: 0 }}
      >
        <div className={classes.vdiv} style={{ alignItems: "center" }}>
          <span className={classes.ispan} style={{ textAlign: "center" }}>
            {Icon("action", IconCheckBoxOn, "1.25em", null, { fontSize: "1em" })}
          </span>
        </div>
      </div>
    </TooltipR>
  )
);

const CellContentRowNumberHeader = React.memo(
  ({ classes, keys, style, bindData }) => (
    <div
      className={clsx(classes.common, classes.haxis)}
      {...bindData}
      id={keys}
      key={keys}
      style={{ ...style, borderLeft: 0 }}
    >
      <div className={classes.vdiv}>
        <span className={classes.ispan}>
          {Icon("action", IconRowNumber, "1.5em", null, { fontSize: "1em" })}
        </span>
      </div>
    </div>
  )
);

const CellContentRowCollapseHeader = React.memo(
  ({ classes, keys, style, bindData }) => (
    <div
      className={clsx(classes.common, classes.haxis)}
      {...bindData}
      id={keys}
      key={keys}
      style={{ ...style, borderLeft: 0 }}
    >
      <div className={classes.vdiv}>
        <span className={classes.ispan} />
      </div>
    </div>
  )
);

const HAxisContent = React.memo((props) => {
  const { classes, keys, style, head, bindData } = props;
  return (
    <div
      className={clsx(classes.common, classes.haxis)}
      {...bindData}
      id={keys}
      key={keys}
      style={style}
    >
      <div className={classes.hdiv}>
        <span className={classes.ispanBig}>
          {head.title || head.name || "-"}
        </span>
        {head.title && (
          <span className={classes.ispanSmall}>{head.name || "-"}</span>
        )}
        {props.withTypeName !== false && (
          <span className={classes.ispanSmall}>
            {`[${head.typename || "-"}]`}
          </span>
        )}
      </div>
    </div>
  );
});

const CellContentCheckBox = React.memo(
  ({ classes, keys, style, id, columnIndex, bindData, tableState }) => {
    const theme = useTheme();
    const isOn = tableState.selectedRows?.indexOf(id) !== -1;

    return (
      <div
        className={clsx(classes.common, classes.vaxis)}
        {...bindData}
        id={keys}
        key={keys}
        style={{ ...style, borderLeft: "none", cursor: "pointer", padding: "0 0.5em" }}
      >
        <div className={classes.vdiv} style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "0 0.125em 0 0.15em" }}>
          <span className={classes.ispan}>
            {Icon(
              "action",
              isOn ? IconCheckBoxOn : IconCheckBoxOff,
              "1.25em",
              theme.palette.action[isOn ? "checkbox" : "active"],
              { fontSize: "1em", marginLeft: "0.1em" }
            )}
          </span>
        </div>
      </div>
    );
  }
);

const CellContentRowNumber = React.memo(
  ({ classes, keys, style, columnIndex, rowIndex, bindData }) => {
    const styles = React.useMemo(
      () => ({
        ...style,
        ...(columnIndex === 0 ? { borderLeft: "none", cursor: "pointer" } : {}),
      }),
      [columnIndex, style]
    );

    return (
      <div
        className={clsx(classes.common, classes.vaxis)}
        {...bindData}
        id={keys}
        key={keys}
        style={styles}
      >
        <div className={classes.vdiv}>
          <span className={classes.ispan}>{rowIndex + 1}</span>
        </div>
      </div>
    );
  }
);

const CellContentRowCollapse = React.memo(
  ({ classes, keys, style, recalculateWidth, nestedEmptyRows, nestedDataLoad, nestedLoading, setNestedLoading, tableState, setTableState, nestedRowsCount, nestedRowsSpace, rowIndex, bindData }) => {
    const codeIndex = React.useMemo(() => tableState.columns.findIndex(({ name }) => name === "code"), [tableState]);
    const code = React.useMemo(() => tableState.rows[rowIndex][codeIndex], [codeIndex, rowIndex, tableState]);

    const parentCodeIndex = React.useMemo(() => tableState.columns.findIndex(({ name }) => name === "parent_code"), [tableState]);
    const parentCode = React.useMemo(() => tableState.rows[rowIndex][parentCodeIndex], [parentCodeIndex, rowIndex, tableState]);

    const handleExpandClick = React.useCallback(async () => {
      if (nestedLoading || nestedEmptyRows.get(code)) return;

      const count = nestedRowsCount.get(code);
      if (count) {
        let totalCount = 0;
        let childrensLeft = count;
        let index = rowIndex + 1;
        const codesToDelete = [];

        while (childrensLeft > 0) {
          const row = tableState.rows[index];
          totalCount++;
          index++;
          
          const rowCode = row[codeIndex];
          if (nestedRowsCount.has(rowCode)) {
            codesToDelete.push(rowCode);
            childrensLeft += nestedRowsCount.get(rowCode);
          }

          childrensLeft--;
        }

        const newRows = [...tableState.rows];
        newRows.splice(rowIndex + 1, totalCount);
  
        nestedRowsCount.delete(code);
        nestedRowsSpace.delete(code);

        codesToDelete.forEach(code => {
          nestedRowsCount.delete(code);
          nestedRowsSpace.delete(code);
        })

        setTableState({ ...tableState, rowCount: tableState.rowCount - totalCount, rows: newRows });
        recalculateWidth();
        return;
      }

      setNestedLoading(code);

      const rows = await nestedDataLoad(code);
      if (!Array.isArray(rows) || rows.length === 0) {
        nestedEmptyRows.set(code, true);
        setNestedLoading(false);
        return;
      }

      const newRows = [...tableState.rows];
      newRows.splice(rowIndex + 1, 0, ...rows);

      nestedRowsCount.set(code, rows.length);
      nestedRowsSpace.set(code, (nestedRowsSpace.get(parentCode) || 0) + 1);

      setTableState({ ...tableState, rowCount: tableState.rowCount + rows.length, rows: newRows });
      recalculateWidth();
      setNestedLoading(false);
    }, [nestedLoading, setNestedLoading, nestedEmptyRows, nestedRowsCount, code, nestedDataLoad, tableState, rowIndex, nestedRowsSpace, parentCode, setTableState, recalculateWidth, codeIndex, parentCodeIndex]);

    const count = nestedRowsCount.get(code);
    const empty = nestedEmptyRows.get(code) || code === null;
    const space = nestedRowsSpace.get(parentCode);
    const loading = nestedLoading === code;

    return (
      <div
        className={clsx(classes.common, classes.vaxis)}
        {...bindData}
        id={keys}
        key={keys}
        onClick={handleExpandClick}
        style={{ ...style, borderLeft: "none", cursor: !loading && "pointer" }}
      >
        <div className={classes.vdiv} style={{ display: "flex", alignItems: "start", justifyContent: "center", marginLeft: (space || 0) * 12 }}>
          {loading 
            ? <CircularProgress size="1.2em" color="primary" style={{ margin: "0 0.15em" }} />
            : empty
              ? <div style={{ width: "1.5em", height: "1.5em" }} />
              : Icon("action", count ? IconRowCollapse : IconRowExpand, "1.5em", null, { fontSize: "1em" })
          }
        </div>
      </div>
    );
  }
);

const FixedSpanGrid = withTheme(({ data, setData, ...props }) => {
  const [isLoading, setLoading] = useState(true);

  const [widths, setWidths] = useState([]);
  const [heights, setHeights] = useState([]);
  const [topLeft, setTopLeft] = useState({ top: 0, left: 0 });
  const [forseRecalculate, setForceRecalculate] = React.useState(false);
  const [nestedLoading, setNestedLoading] = React.useState(false);

  const nestedRowsCount = React.useMemo(() => new Map(), []);
  const nestedEmptyRows = React.useMemo(() => new Map(), []);
  const nestedRowsSpace = React.useMemo(() => new Map(), []);

  const visible = useRef({ column: 30, row: 30 });
  const classes = useStyles();

  useEffect(() => {
    if (!props.recalculateFn) return;
    props.recalculateFn.current = () => setForceRecalculate(v => !v);
  }, []);

  const recalculateTable = React.useCallback(() => {
    setWidths([]);
    setHeights([]);
    setTopLeft(initial => {
      setTimeout(() => setTopLeft(initial), 0);
      return { top: 9999999, left: 9999999 }
    })
  }, []);
  
  const recalculateWidth = React.useCallback(() => {
    setWidths([]);
    setTopLeft(initial => {
      setTimeout(() => setTopLeft(initial), 0);
      return { top: 9999999, left: 9999999 }
    })
  }, []);

  useEffect(() => {
    recalculateTable()
  }, [props.recalculateHeights, recalculateTable]);
 
  useEffect(() => {
    data.fixedRowCount = 1;
    data.fixedColCount = data.columnsIndex.fixedCols.length;
    data.colCount = data?.columnsIndex.indexes.length;
    data.rowCount = data?.rows?.length;

    setData(data);
    setLoading(false);
  }, [data, setData]);

  const onResizeMouseUp = React.useCallback((bind, event) => {
    window.document.body.style.removeProperty("cursor");
    window.removeEventListener("mousemove", bind.onResizeMouseMove);
    window.removeEventListener("mouseup", bind.onResizeMouseUp);

    const { head, startX } = bind;
    const z = event.clientX - startX;
    if (!z) head.data.properties.width = undefined;
  }, []);

  const onResizeMouseMove = React.useCallback(
    (bind, event) => {
      if (event.buttons === 0) {
        onResizeMouseUp(bind, event);
        return;
      }

      const { head, startX, startWidth } = bind;
      const z = event.clientX - startX;
      head.data.properties.width = startWidth + z;

      setTopLeft({ ...topLeft });
    },
    [onResizeMouseUp, topLeft]
  );

  const onResizeStart = React.useCallback(
    (head, columnIndex, event) => {
      if (event.button !== 0) return;
      window.document.body.style.cursor = "ew-resize";

      const bind = {
        head,
        columnIndex,
        startX: event.clientX,
        startWidth: widths[columnIndex],
      };

      bind.onResizeMouseMove = onResizeMouseMove.bind(null, bind);
      bind.onResizeMouseUp = onResizeMouseMove.bind(null, bind);
      window.addEventListener("mousemove", bind.onResizeMouseMove);
      window.addEventListener("mouseup", bind.onResizeMouseUp);
    },
    [onResizeMouseMove, widths]
  );

  const ResizeHandlerContent = React.useCallback(
    (props) => {
      const { classes, keys, style, head, columnIndex, bindData } = props;
      if (!head) return null;
      return (
        <div
          className={classes.resizeColumn}
          {...bindData}
          id={keys}
          key={keys}
          style={style}
          onMouseDown={
            head?.data ? onResizeStart.bind(null, head, columnIndex) : null
          }
        >
          <div className={classes.resizeHandler} />
        </div>
      );
    },
    [onResizeStart]
  );

  const columnInfo = React.useCallback(
    (columnIndex) => {
      const fixedCol = data.fixedColCount || 0;
      let columnNdx;
      let fixed;
      if (columnIndex >= fixedCol) {
        columnIndex -= fixedCol;
        columnNdx = data.columnsIndex.indexes[columnIndex];
        fixed = false;
      } else {
        columnNdx = data.columnsIndex.fixedCols[columnIndex];
        fixed = true;
      }
      const head = data.columns[columnNdx];
      return { fixed, columnIndex, head, columnNdx };
    },
    [data]
  );

  const cellRenderer = React.useCallback(
    (
      coord,
      classes,
      tableState,
      { columnIndex, key, rowIndex, style, bindData },
      update
    ) => {
      let cell = null;
      let id = null;
      let Type = null;
      let typeName = null;

      const fixedRow = data.fixedRowCount || 0;

      style.maxHeight = props.height;

      const { columnNdx, fixed, head } = columnInfo(columnIndex);

      if (key === props.editorData?.id) {
        typeName = "editor";
        return (
          <EditorContent
            key={key}
            ndx={key}
            tableState={data}
            classes={classes}
            bindData={{
              ...bindData,
              ...{ row: rowIndex, col: columnIndex, type: typeName },
            }}
            keys={key}
            cell={cell}
            head={head}
            style={style}
            editorData={props.editorData}
            setEditorData={props.setEditorData}
          />
        );
      }

      if (rowIndex === -1) {
        Type = ResizeHandlerContent;
        typeName = "resize";
        cell = head;
      } else if (rowIndex < fixedRow) {
        switch (columnNdx) {
          case -2: {
            Type = CellContentCheckBoxHeader;
            typeName = "checkbox";
            break;
          }
          case -3: {
            Type = CellContentRowNumberHeader;
            typeName = "rownumber";
            break;
          }
          case -4: {
            Type = CellContentRowCollapseHeader;
            typeName = "collapse";
            break;
          }
          default: {
            Type = props.cellRendererContext?.HAxisContent || HAxisContent;
            break;
          }
        }
        typeName = "ha";
        cell = head;
      } else {
        rowIndex -= fixedRow;
        switch (columnNdx) {
          case -2: {
            Type = CellContentCheckBox;
            typeName = "checkbox";
            break;
          }
          case -3: {
            Type = CellContentRowNumber;
            typeName = "rownumber";
            break;
          }
          case -4: {
            Type = CellContentRowCollapse;
            typeName = "collapse";
            break;
          }
          default: {
            Type = props.cellRendererContext?.CellContent || CellContent;
            typeName = "data";
            cell = data.rows[rowIndex][columnNdx];
            break;
          }
        }
        id = data.rows[rowIndex][data.columnsIndex.id];
      }

      return (
        <Type
          fixed={fixed}
          key={key}
          withTypeName={props.withTypeName}
          tableState={data}
          setTableState={setData}
          recalculateWidth={recalculateWidth}
          nestedLoading={nestedLoading}
          nestedEmptyRows={nestedEmptyRows}
          setNestedLoading={setNestedLoading}
          nestedDataLoad={props.nestedDataLoad}
          nestedRowsCount={nestedRowsCount}
          nestedRowsSpace={nestedRowsSpace}
          classes={classes}
          bindData={{
            ...bindData,
            ...{
              row: rowIndex,
              col: columnIndex,
              type: typeName,
              colndx: columnNdx,
            },
          }}
          keys={key}
          cell={cell}
          head={head}
          id={id}
          style={style}
          columnIndex={columnIndex}
          rowIndex={rowIndex}
        />
      );
    },
    [data, columnInfo, props.editorData, props.withTypeName, props.nestedDataLoad, props.setEditorData, props.cellRendererContext?.HAxisContent, props.cellRendererContext?.CellContent, setData, recalculateWidth, nestedLoading, nestedEmptyRows, nestedRowsCount, nestedRowsSpace, ResizeHandlerContent]
  );

  const measure_w = React.useCallback(
    (xm, ym, y1, y2, x1, x2) => {
      x2 = Math.min(x2, xm);
      y2 = Math.min(y2, ym);
      for (let i = x1; i < x2; i++) {
        const columnIndex = i;
        const { head } = columnInfo(columnIndex);
        if (head?.data?.properties.width) {
          widths[i] = head.data.properties.width + 1;
        } else {
          for (let k = y1; k < y2; k++) {
            const rowIndex = k;
            const key = `${data.id}:${columnIndex}-${rowIndex}`;
            const node = window[key]?.length ? window[key][0] : window[key];
            if (node && (node.style.width === "auto" || node.style.width === "max-content")) {
              widths[i] = Math.max(
                widths[i] || 0,
                Math.ceil(node.offsetWidth) + 1
              );
            }
          }
        }
      }
    },
    [columnInfo, data, widths]
  );

  const measure_setW = React.useCallback(
    (xm, ym, y1, y2, x1, x2) => {
      x2 = Math.min(x2, xm);
      y2 = Math.min(y2, ym);
      for (let k = y1; k < y2; k++) {
        const rowIndex = k;
        for (let i = x1; i < x2; i++) {
          const columnIndex = i;
          const width = widths[columnIndex];

          const key = `${data.id}:${columnIndex}-${rowIndex}`;
          const node = window[key]?.length ? window[key][0] : window[key];
          if (node && node.style.width === "auto") {
            let nodeWidth = width;
            const spanx = Number(node.attributes.spanx.value);
            for (let x = 1; x < spanx; x++)
              nodeWidth += widths[columnIndex + x] - 1 || 0;
            node.style.width = nodeWidth + "px";
            node.style.maxWidth = null;
            node.style.whiteSpace = null;
          }
        }
      }
    },
    [data, widths]
  );

  const measure_h = React.useCallback(
    (xm, ym, y1, y2, x1, x2) => {
      x2 = Math.min(x2, xm);
      y2 = Math.min(y2, ym);
      for (let k = y1; k < y2; k++) {
        const rowIndex = k;
        for (let i = x1; i < x2; i++) {
          const columnIndex = i;
          const key = `${data.id}:${columnIndex}-${rowIndex}`;
          const node = window[key]?.length ? window[key][0] : window[key];
          if (node && node.style.height === "auto") {
            heights[k] = Math.max(
              heights[k] || 0,
              Math.ceil(node.offsetHeight)
            );
          }
        }
      }
    },
    [data, heights]
  );

  useLayoutEffect(() => {
    if (!data) return;

    const top = topLeft.top;
    const left = topLeft.left;

    const hW = data.fixedRowCount || 0;
    const vW = data.fixedColCount || 0;

    const xm = vW + data?.colCount;
    const ym = hW + data?.rowCount;

    measure_w(xm, ym, 0, hW, 0, vW); // Fixed Horizontal axis
    measure_w(xm, ym, 0, hW, vW + left, vW + left + visible.current.column); // Horizontal axis
    measure_w(xm, ym, hW + top, hW + top + visible.current.row, 0, vW); // Vertical axis
    measure_w(
      xm,
      ym,
      hW + top,
      hW + top + visible.current.row,
      vW + left,
      vW + left + visible.current.column
    );

    measure_setW(xm, ym, 0, hW, 0, vW); // Fixed Horizontal axis
    measure_setW(xm, ym, 0, hW, vW + left, vW + left + visible.current.column); // Horizontal axis
    measure_setW(xm, ym, hW + top, hW + top + visible.current.row, 0, vW); // Vertical axis
    measure_setW(
      xm,
      ym,
      hW + top,
      hW + top + visible.current.row,
      vW + left,
      vW + left + visible.current.column
    );

    measure_h(xm, ym, 0, hW, 0, vW); // Fixed Horizontal axis
    measure_h(xm, ym, 0, hW, vW + left, vW + left + visible.current.column); // Horizontal axis
    measure_h(xm, ym, hW + top, hW + top + visible.current.row, 0, vW); // Vertical axis
    measure_h(
      xm,
      ym,
      hW + top,
      hW + top + visible.current.row,
      vW + left,
      vW + left + visible.current.column
    );

    const pos = { x: 0, y: 0, startX: 0, startY: 0 };
    for (let i = 0; i < vW; i++) pos.startX += widths[i] - 1;
    for (let i = 0; i < hW; i++) pos.startY += heights[i] - 1;

    let lastVisibleColumn = vW + left;
    for (
      let w = pos.startX;
      lastVisibleColumn < xm && w < props.width;
      lastVisibleColumn++
    )
      w += widths[lastVisibleColumn] - 1;

    let lastVisibleRow = hW + top;
    for (
      let h = pos.startY;
      lastVisibleRow < ym && h < props.height;
      lastVisibleRow++
    )
      h += heights[lastVisibleRow] - 1;

    // height for resize handlers
    heights[-1] = props.height;

    // resize handlers
    geometry_rh(
      xm,
      ym,
      data,
      heights,
      widths,
      -1,
      0,
      vW + left,
      vW + left + visible.current.column,
      pos,
      lastVisibleColumn,
      lastVisibleRow
    );

    // Horizontal axis
    pos.y = 0;
    geometry(
      xm,
      ym,
      data,
      heights,
      widths,
      0,
      hW,
      vW + left,
      vW + left + visible.current.column,
      pos,
      lastVisibleColumn,
      lastVisibleRow
    );

    // Fixed resize handlers
    pos.startX = 0;
    pos.x = 0;
    pos.y = 0;
    geometry_rh(
      xm,
      ym,
      data,
      heights,
      widths,
      -1,
      0,
      0,
      vW,
      pos,
      lastVisibleColumn,
      lastVisibleRow
    );

    // FixedHorizontal axis
    pos.startX = 0;
    pos.x = 0;
    pos.y = 0;
    geometry(
      xm,
      ym,
      data,
      heights,
      widths,
      0,
      hW,
      0,
      vW,
      pos,
      lastVisibleColumn,
      lastVisibleRow
    );

    // Vertical axis
    pos.startX = 0;
    const dataPos = { x: 0, y: pos.y, startX: 0 };
    geometry(
      xm,
      ym,
      data,
      heights,
      widths,
      hW + top,
      hW + top + visible.current.row,
      0,
      vW,
      pos,
      lastVisibleColumn,
      lastVisibleRow
    );
    // Facts
    dataPos.x = dataPos.startX = pos.x;
    geometry(
      xm,
      ym,
      data,
      heights,
      widths,
      hW + top,
      hW + top + visible.current.row,
      vW + left,
      vW + left + visible.current.column,
      dataPos,
      lastVisibleColumn,
      lastVisibleRow
    );

    props.onSectionRendered &&
      props.onSectionRendered({
        startIndex: top,
        stopIndex: lastVisibleRow - hW,
      });

    const update =
      visible.current.column < lastVisibleColumn - left ||
      visible.current.row < lastVisibleRow - top;
    visible.current = {
      column: lastVisibleColumn - left,
      row: lastVisibleRow - top,
    };
    update && setTopLeft({ ...topLeft });
  }, [
    columnInfo,
    data,
    heights,
    measure_h,
    measure_setW,
    measure_w,
    props,
    topLeft,
    widths,
  ]);

  const handleTableScroll = React.useCallback(
    (event) => {
      if (event.nativeEvent.target?.id !== `${data?.id}:scroller`) return;
      const top = Math.max(
        0,
        Math.floor(event.nativeEvent.target.scrollTop / 100)
      );
      const left = Math.max(
        0,
        Math.floor(event.nativeEvent.target.scrollLeft / 100)
      );
      if (topLeft.top === top && topLeft.left === left) return;
      setTopLeft({ top, left });
    },
    [data, topLeft]
  );

  const containerStyles = React.useMemo(
    () => ({
      display: "flex",
      flexDirection: "column",
      width: props.width,
      height: props.height,
    }),
    [props.height, props.width]
  );

  const tableCntainerStyles = {
    WebkitTransform: "translate3d(0px, 0px, 0px)",
    display: "flex",
    width: props.width + ((data?.colCount || 1) - 1) * 100,
    height: props.height + ((data?.rowCount || 1) - 1) * 100,
    fontSize: "13px",
  };

  return (
    <div style={containerStyles}>
      {isLoading ? (
        <div style={{ display: "flex", alignItems: "center", height: "100%", width: "100%", justifyContent: "center" }}>
          <CircularProgress color="primary" size={"32px"} />
        </div>
      ) : (
        <div
          id={`${data?.id}:scroller`}
          onScroll={handleTableScroll}
          style={{
            display: "block",
            height: "100%",
            width: "100%",
            overflow: "auto",
          }}
        >
          <div id={`${data?.id}:sizer`} style={tableCntainerStyles}>
            <ScrollArea
              recalculate={recalculateTable}
              forseRecalculate={forseRecalculate}
              classes={classes}
              data={data}
              topLeft={topLeft}
              visible={visible}
              cellRenderer={cellRenderer}
            />
          </div>
          {data.rows.length === 0 && (
            <div style={{ position: "absolute", top: "calc(50% + 30px)", left: "50%", transform: "translate(-50%, -50%)" }}>
              Список данных пуст
            </div>
          )}
        </div>
      )}
    </div>
  );
});

const StyledLink = styled("div")`
  cursor: pointer;
  transition: color .15s ease-in-out;

  &:hover {
    color: #0071e3;
  }
`;

export default React.memo(FixedSpanGrid);
