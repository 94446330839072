import React from "react";
import { capitalize } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Menu, MenuItem } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import styled from "styled-components";
import PopupState, { bindMenu } from "material-ui-popup-state";

import BitArray from "shared/utils/BitArray";
import { ToolButton, StyledButtonGroup, ToolButtonComponent } from "shared/ui/ToolBar";
import { useConfirmationDialog } from "shared/ui/ConfirmationDialog";

import ItemDialog from "components/itemDialog";
import DataTableView from "components/itemView/DataTableView";
import ScrollableContainer from "components/ScrollableContainer";
import PdfDocRegLoader from "components/itemView/Registry/Modals/PdfDocRegLoader";

import { ReactComponent as IconSort } from "shared/icon/sorting.svg";
import { ReactComponent as IconColumns } from "shared/icon/columns.svg";
import { ReactComponent as IconFilterRemove } from "shared/icon/filter_remove.svg";
import { ReactComponent as IconDownload} from "shared/icon/download.svg"

import { ReactComponent as IconReload } from "@mdi/svg/svg/reload.svg";
import { ReactComponent as IconDots } from "@mdi/svg/svg/dots-vertical.svg";
import { ReactComponent as AllFieldsIcon } from "@mdi/svg/svg/file-document-outline.svg";

import TableEditorToolbutton from "./TableEditorToolbutton";

export const TOOLBAR_DISABLE_CASES = {
  more_than_zero: "more_than_zero",
  equal_one: "equal_one",
};

export const DUPLICATE_INDENTIFICATOR = "_7773297_"

function parseNumber(number) {
  const stringValue = String(number);
  const stringLength = stringValue.length - 1;
  const result = [];

  for (let i = 0; i <= stringLength; i++) {
    result.push(stringValue[i]);
    const needSpace = (stringLength - i) % 3 === 0;
    if (i === stringLength || !needSpace) continue;
    result.push(" ");
  }

  return result.join("");
}

function TableComponent(
  {
    registry,
    value,
    onChange,
    schema,
    rerenderDeps,
    removeViewAllFieldsToolbutton,
    onFiltersChange,
    onOrderChange,
    customOrderChange,
    saveSelectedRows,
    getSelected,
    tags = [],
    nesting,
    onTagsChange,
    idInFilters,
    onColumnDrop,
    onHiddenColumnsChange,
    recalculateHandler,
    pushDataHandler,
    removeDataHandler,
    createdIds,
    onRowClick,
    customTableDataGetter,
    onDropHiddenColumn,
    dropHandler,
    onEditCell,
    customSortOrder,
    onReload,
    customToolbarRightSideContent,
    tableDataHandler,
    filtersHandler,
    cantEditCell,
    removeTopBorder,
    customContent,
    hiddenColumnDrag,
    reloadOnFiltersChange,
    tableSettings,
    rowsHandler,
    reloadHandler,
    setRowsHandler,
    clearFiltersHandler,
  },
  tableRef
) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const confirmationDialog = useConfirmationDialog();

  const api = useSelector((state) => state.API);
  const app = useSelector((state) => state.APP);

  const [selectedRows, setSelectedRows] = React.useState([]);
  const [tableData, setTableData] = React.useState([]);
  
  const [elementsCount, setElementsCount] = React.useState(null);
  const [elementsCountLoading, setElementsCountLoading] = React.useState(true);

  const trDims = schema.data.trDims;
  const apiMethod = schema.data.apiMethod;
  const isTableEmpty = schema.data.isTableEmpty;
  const apiPayload = schema.data.apiPayload;
  const itemsCounter = schema.data.itemsCounterRequest;
  const selectedCounter = schema.data.selectedCounter;

  const filtersFn = React.createRef();
  const setRowsFn = React.useRef();
  const clearSortingFn = React.useRef();
  const reloadFn = React.createRef();
  const recalculateFn = React.useRef();
  const dropFn = React.createRef();
  const clearFiltersFn = React.createRef();
  const removeDataFn = React.useRef();
  const pushDataFn = React.useRef();

  const selectedRowsOnce = React.useRef(null);

  const handleReload = (saveRows = null) => {
    selectedRowsOnce.current = saveRows;
    reloadFn.current?.()
  };
  const handleFilters = () => filtersFn.current?.() || [];
  const clearFilters = () => clearFiltersFn.current?.();
  
  React.useEffect(() => pushDataHandler?.(() => (value) => {
    setElementsCount(count => count + 1);
    pushDataFn.current(value);
  }), [pushDataHandler]);
  React.useEffect(() => removeDataHandler?.(() => (value) => {
    setElementsCount(count => count - 1);
    removeDataFn.current(value);
  }), [removeDataHandler]);
  React.useEffect(() => recalculateHandler?.(() => () => recalculateFn.current?.()), [recalculateHandler]);
  React.useEffect(() => tableDataHandler?.(tableData), [tableData, tableDataHandler]);
  React.useEffect(() => rowsHandler?.(selectedRows), [selectedRows, rowsHandler]);
  React.useEffect(() => setRowsHandler?.(() => setRowsFn.current), [setRowsHandler]);
  React.useEffect(() => dropHandler?.(() => () => dropFn.current?.()), [dropHandler]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => reloadHandler?.(() => handleReload), [reloadHandler]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => filtersHandler?.(() => handleFilters), [filtersHandler]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => clearFiltersHandler?.(() => clearFilters), [clearFiltersHandler]);

  const loadTableData = (perPage, page) =>
    new Promise(async (resolve) => {
      try {
        const filter = filtersFn.current?.();
        const isDataAxis = (d) => ["data", "fixed"].includes(d.properties.axis);

        const fields = ["id", "*"].concat(trDims.filter((d) => isDataAxis(d)).map((d) => d.properties.name));

        const sort_order = customSortOrder?.current || [];
        if (!customSortOrder?.current) {
          trDims
            .filter((d) => isDataAxis(d))
            .filter((d) => ["DESC", "ASC"].includes(d.properties.sortOrder))
            .forEach((d) => {
              const name = d.properties.name.split(DUPLICATE_INDENTIFICATOR)[0];
              const isAlreadyExist = sort_order.find(([itemCode]) => itemCode === name);
              if (isAlreadyExist) return;
              sort_order.push([name, d.properties.sortOrder]);
            });
        }

        const requestFilter = [...(apiPayload?.filter ? apiPayload.filter : []), ...filter]
        if (requestFilter.length > 0) requestFilter.unshift(
          ...(createdIds?.current || []).map((id) => [null, "id", "=", id, false, null, "OR"])
        )

        let data = isTableEmpty ? [] : customTableDataGetter
          ? await customTableDataGetter({ perPage, page })
          : apiMethod
          ? await api.send(apiMethod, {
              page_size: perPage,
              page_number: page,
              sort_order,
              ...(apiPayload || {}),
              filter: requestFilter,
            })
          : [];

        if (itemsCounter && page === 0) {
          setElementsCountLoading(true)
          api.call(schema.data.itemsCounterRequest, { 
            ...(apiPayload || {}),
            filter: requestFilter,
          })
          .then(res => {
            setElementsCountLoading(false);
            if (!res || typeof res?.count !== "number") return;
            setElementsCount(res.count);
          });
        }

        if (!data || data.error) return resolve({ error: true, message: data.message });
        if (schema.data.apiClientSort) data = schema.data.apiClientSort(data);
        setTableData((prevData) => (page === 0 ? data : [...prevData, ...data]));

        const duplicateColumnsCount = {};
        trDims.forEach(({ properties }) => {
          const name = properties.name.split(DUPLICATE_INDENTIFICATOR)[0];
          duplicateColumnsCount[name] = (duplicateColumnsCount[name] || 0) + 1;
        });
        const duplicatedColumnKeys = Object.keys(duplicateColumnsCount).filter(key => duplicateColumnsCount[key] > 1);
        
        const dataColumns = Object.keys(data?.[0] || {});
        const columns_name = dataColumns.length > 0 ? dataColumns : fields;
        const columns = columns_name.map((name) => ({ name }));

        duplicatedColumnKeys.forEach(key => new Array(duplicateColumnsCount[key] - 1).fill(1).forEach((_, index) => {
          const newName = key + DUPLICATE_INDENTIFICATOR + index;
          columns.push({ name: newName });
          columns_name.push(newName);
          data.forEach(row => row[newName] = row[key]);
          
          const trDim = trDims.filter(tr => tr.properties.name === key)[1];
          if (!trDim) return;
          const trDimIndex = trDims.findIndex(({ id }) => id === trDim.id);
          trDims[trDimIndex].properties.name = newName;
        }));

        const rows = (data?.length > 0 && data.map((r) => Object.values(r))) || [];

        for (let d of trDims) {
          const ndx = columns_name.findIndex((name) => name === d.properties.name);
          if (ndx === -1) continue;
          const dict = trDims.find((i) => i.id === d.id);
          if (!dict?.length) continue;
          const dictMap = dict.reduce((map, r) => {
            map[r[3]] = r[1]; // map[r.code] = r.name
            return map;
          }, {});
          rows.forEach((r) => (r[ndx] = dictMap[r[ndx]]));
        }

        const columnIndex = (d) => {
          const ndx = columns_name.findIndex((name) => name === d.properties.name);
          return ndx === -1 ? columns_name.push(d.properties.name) - 1 : ndx;
        };

        const columnsDim = trDims.filter((d) => isDataAxis(d));
        const fixedCols = columnsDim
          .filter((d) => d.properties.axis === "fixed" && !d.properties.hidden)
          .sort((l, r) => (l.properties.position < r.properties.position ? -1 : 1))
          .map((d) => columnIndex(d));

        const indexes = columnsDim
          .filter((d) => d.properties.axis === "data" && !d.properties.hidden)
          .sort((l, r) => (l.properties.position < r.properties.position ? -1 : 1))
          .map((d) => columnIndex(d));

        fixedCols.unshift(-2);
        nesting && fixedCols.unshift(-4);

        const foundIdColumnIndex = columns_name.findIndex((name) => name === "id");
        const columnsIndex = {
          id: foundIdColumnIndex !== -1 ? foundIdColumnIndex : columns_name.findIndex((name) => name === "code"),
          indexes,
          fixedCols,
        };

        const resultSelectedRows = Array.isArray(selectedRowsOnce.current) 
          ? selectedRowsOnce.current
          : saveSelectedRows
            ? selectedRows.filter((id) => !!data.find((element) => element.id === id))
            : [];

        selectedRowsOnce.current = null;
        
        resolve({
          rows,
          columns,
          selectedRows: resultSelectedRows,
          columnsIndex,
        });
      } catch (e) {
        resolve({ error: e.message });
      }
    });

  const handleExpandRowDataLoad = React.useCallback(async (code) => {
    const data = await api.send(apiMethod, {
      code: apiPayload.code || "terson",
      page_size: 999999,
      page_number: 0,
      sort_order: [],
      filter: [[null, "parent_code", "=", code, true, null, "AND"]],
    });

    if (!Array.isArray(data) || data.length === 0) return [];

    const isDataAxis = (d) => ["data", "fixed"].includes(d.properties.axis);
    const fields = ["id", "*"].concat(trDims.filter((d) => isDataAxis(d)).map((d) => d.properties.name));
    const rows = (data?.length > 0 && data.map((r) => Object.values(r))) || [];

    const duplicateColumnsCount = {};
    trDims.forEach(({ properties }) => {
      const name = properties.name.split(DUPLICATE_INDENTIFICATOR)[0];
      duplicateColumnsCount[name] = (duplicateColumnsCount[name] || 0) + 1;
    });
    const duplicatedColumnKeys = Object.keys(duplicateColumnsCount).filter(key => duplicateColumnsCount[key] > 1);
        
    const dataColumns = Object.keys(data?.[0] || {});
    const columns_name = dataColumns.length > 0 ? dataColumns : fields;
    const columns = columns_name.map((name) => ({ name }));

    duplicatedColumnKeys.forEach(key => new Array(duplicateColumnsCount[key] - 1).fill(1).forEach((_, index) => {
      const newName = key + DUPLICATE_INDENTIFICATOR + index;
      columns.push({ name: newName });
      columns_name.push(newName);
      data.forEach(row => row[newName] = row[key]);
      
      const trDim = trDims.filter(tr => tr.properties.name === key)[1];
      if (!trDim) return;
      const trDimIndex = trDims.findIndex(({ id }) => id === trDim.id);
      trDims[trDimIndex].properties.name = newName;
    }));

    for (let d of trDims) {
      const ndx = columns_name.findIndex((name) => name === d.properties.name);
      if (ndx === -1) continue;
      const dict = trDims.find((i) => i.id === d.id);
      if (!dict?.length) continue;
      const dictMap = dict.reduce((map, r) => {
        map[r[3]] = r[1]; // map[r.code] = r.name
        return map;
      }, {});
      rows.forEach((r) => (r[ndx] = dictMap[r[ndx]]));
    }

    const newData = [...tableData];
    const foundDataIndex = tableData.findIndex((data) => data.code === code);
    newData.splice(foundDataIndex + 1, 0, ...data);
    setTableData(newData);

    return rows;
  }, [api, apiMethod, apiPayload, filtersFn, tableData, trDims]);

  const handleSelectedRowsChanged = (ids) => {
    setSelectedRows([...ids]);
    if (!onChange) return;
    onChange(
      value,
      schema.value,
      null,
      schema.data.returnRowsData ? ids.map((id) => tableData.find((data) => data.id === id)) : ids
    );
  };

  const toolbuttonDisableStatus = React.useCallback(async (status) => {
    if (typeof status === "boolean") return status; 
    if (typeof status === "function") return toolbuttonDisableStatus(await status({ tableData, rows: selectedRows }))
    if (status === TOOLBAR_DISABLE_CASES.equal_one) return selectedRows.length === 1;
    if (status === TOOLBAR_DISABLE_CASES.more_than_zero) return selectedRows.length > 0;
    return false;
  }, [selectedRows, tableData]);

  const createToolbar = React.useCallback(
    (toolbar) =>
      toolbar.map(([icon, handler, disabledStatus, label, key, color, text, menu, active], index) => {
        if (menu) {
          return <TableEditorToolbutton 
            handleReload={handleReload} 
            key={index} 
            selectedRows={selectedRows} 
            tableData={tableData} 
            toolbuttonDisableStatus={toolbuttonDisableStatus} 
            data={[icon, handler, disabledStatus, label, key, color, text, menu]} 
          />
        }
        
        return (
          <ToolButtonComponent 
            icon={icon} 
            handler={handler.bind(null, {
              tableData,
              rows: selectedRows,
              reload: handleReload,
            })} 
            enabled={async () => await toolbuttonDisableStatus(disabledStatus)} 
            color={color} 
            tooltip={text} 
            active={active}
            key={index} 
          />
        );
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRows, toolbuttonDisableStatus, tableData]
  );

  const navigationToolbar = React.useMemo(() => {
    if (!schema.data.navigationToolbar) return null;
    return createToolbar(schema.data.navigationToolbar);
  }, [createToolbar, schema.data.navigationToolbar]);

  const customToolbar = React.useMemo(() => {
    if (!schema.data.toolbar) return null;
    return createToolbar(schema.data.toolbar);
  }, [createToolbar, schema.data.toolbar]);

  const tableCurrent = React.useMemo(
    () => ({
      current: { id: schema.data.id, dataFn: loadTableData, type: "i18n" },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }),
    [...(rerenderDeps || [])]
  );

  const getSchemaType = React.useCallback((key, value) => {
    if (typeof value === "boolean") return "boolean";
    if (key.includes("date")) return "DateTime";
    return "text";
  }, []);

  const handleAllFieldsView = React.useCallback(async () => {
    const foundData = {
      ...tableData.find((data) => data.id === selectedRows[0]),
    };

    const dataKeys = Object.keys(foundData)
      .filter((key) => key !== "icon" && key !== "picture")
      .sort();
    const additionalBlocks = [];

    if (app === "model") {
      const tagsIndex = dataKeys.findIndex((key) => key === "tags");
      if (tagsIndex !== -1) {
        const tagOptions = (foundData.tags || []).map((tag) => ({
          id: tag,
          name: tag,
        }));
        additionalBlocks.push({
          value: "tags",
          label: "Tags",
          type: "multiSelect",
          data: {
            disabled: true,
            select: tagOptions,
          },
        });
        foundData.tags = tagOptions;
        dataKeys.splice(tagsIndex, 1);
      }

      const dataTypeIndex = dataKeys.findIndex((key) => key === "datatype_id");
      if (dataTypeIndex !== -1) {
        const result = await api.send("datatype/list", {
          filter: [[null, "id", "=", foundData.datatype_id, false, null, "AND"]],
        });
        if (result && result?.length) {
          foundData.dataTypeName = result[0]?.name || "";
          foundData.dataTypeCode = result[0]?.code || "";
          additionalBlocks.push({
            value: "",
            label: "",
            type: "MultiInput",
            data: [
              {
                value: "dataTypeName",
                label: "Datatype Name",
                type: "text",
                data: { disableClean: true, disabled: true, primaryText: true },
              },
              {
                value: "dataTypeCode",
                label: "Datatype Code",
                type: "text",
                data: { disableClean: true, disabled: true, primaryText: true },
              },
            ],
          });
          dataKeys.splice(dataTypeIndex, 1);
        }
      }

      const systemIndex = dataKeys.findIndex((key) => key === "system");
      const versionIndex = dataKeys.findIndex((key) => key === "version");
      if (systemIndex !== -1 && versionIndex !== -1) {
        additionalBlocks.push({
          value: "",
          label: "",
          type: "MultiInput",
          data: [
            {
              value: "system",
              label: "System",
              type: "boolean",
              data: { disabled: true },
            },
            {
              value: "version",
              label: "version",
              type: "text",
              data: { disableClean: true, disabled: true, primaryText: true },
            },
          ],
        });
        dataKeys.splice(systemIndex, 1);
        dataKeys.splice(systemIndex > versionIndex ? versionIndex + 1 : versionIndex - 1, 1);
      }
    }

    const imageBlocks = Object.keys(foundData)
      .filter((key) => key === "icon" || key === "picture")
      .map((key, i) => {
        const blockProps = [];
        for (let dataIndex = i * 3; dataIndex < i * 3 + 3; dataIndex++) {
          const foundDataKey = dataKeys[0];
          if (!foundDataKey) continue;
          blockProps.push({
            value: foundDataKey,
            label: capitalize(foundDataKey.split("_").join(" ")),
            type: getSchemaType(foundDataKey, foundData[foundDataKey]),
            data: { disableClean: true, disabled: true, primaryText: true },
          });
          dataKeys.splice(0, 1);
        }
        return {
          value: key,
          label: capitalize(key),
          type: "imageBlock",
          data: {
            disabled: true,
            withProps: blockProps,
          },
        };
      });

    const schema = {
      props: [
        ...imageBlocks,
        ...dataKeys.map((key) => ({
          value: key,
          label: capitalize(key.split("_").join(" ")),
          type: getSchemaType(key, foundData[key]),
          data: { disableClean: true, disabled: true, primaryText: true },
        })),
        ...additionalBlocks,
      ],
    };

    await confirmationDialog.getConfirmation({
      content: (onOk, onClose) => (
        <ItemDialog
          title={<span>Просмотреть все поля</span>}
          titleIcon={AllFieldsIcon}
          schema={schema}
          prop={{ current: { properties: foundData } }}
          onOk={onOk}
          onClose={onClose}
        />
      ),
      type: "Dialog",
    });
  }, [tableData, app, api, confirmationDialog, selectedRows, getSchemaType]);

  const tableApi = React.useMemo(
    () => ({
      set: (id, properties) =>
        new Promise((resolve) => {
          (async () => {
            try {
              trDims.find((i) => i.id === id).properties = properties;
              resolve({});
            } catch (e) {
              resolve({ error: true });
            }
          })();
        }),
      dimensionValues: (id, page, pageSize, filter, condition) =>
        new Promise((resolve) => {
          (async () => {
            try {
              const column = trDims.find((i) => i.id === id);
              function getDictData(dict) {
                const data = {
                  value: dict.name, 
                  key: dict.code || dict.id, 
                  index: dict.index, 
                  size: dict.size,
                  parent: dict.parent
                }
                if (dict.expanded) data.expanded = true;
                if (dict.childrens) data.childrens = dict.childrens.map(getDictData)
                return data
              }

              function checkFilterValue(dict) {
                const value = dict.name;
                if (typeof value !== "string" || typeof filter !== "string") return false;

                const lowerCaseValue = value.toLowerCase();
                const lowerCaseFilter = filter.toLowerCase();
            
                if (condition === "contains") return lowerCaseValue.includes(lowerCaseFilter);
                if (condition === "equal") return lowerCaseValue === lowerCaseFilter;
                if (condition === "starts_with") return lowerCaseValue.startsWith(lowerCaseFilter);
                return false;
              }

              function filterDicts(dict) {
                if (dict.childrens) {
                  dict.childrens = dict.childrens.filter(filterDicts);

                  if (dict.childrens.length === 0) {
                    dict.expanded = false;
                    return checkFilterValue(dict);
                  }

                  dict.expanded = true;
                  return true;
                }

                return checkFilterValue(dict);
              }

              const dicts = JSON.parse(JSON.stringify(column.dict));
              const res = {
                  values: dicts.filter(dict => {
                    if (!filter || filter.length === 0) return true; 
                    return filterDicts(dict);
                  }).map(getDictData)
              }
              resolve(res)
            } catch (e) {
              resolve({ error: true });
            }
          })();
        }),
    }),
    [trDims]
  );

  const customGetSelected = React.useCallback((id, ignoreAllAndNone = false, ndx = 3) => {
    const dim = schema.data.trDims.find(i => i.id === id)
    const f = dim.properties.filter || [];

    const result = dim.dict
    if (!Array.isArray(result)) return []

    const ba = new BitArray(result.length)
    ba.values = f

    if (dim.properties.inverse !== true)
        ba.not()

    const ret = []

    // Если выбрано все или ничего
    if (ba.count() === 0) {
        if (ignoreAllAndNone)
            return []
        result?.forEach((i) => { ret.push(i[ndx]) })
        return ret
    }

    function findSelected(dict) {
      if (dict.childrens) {
        dict.childrens.forEach(findSelected);
        return;
      }
      if (ba.get(dict.index)) ret.push(dict.id)
    }

    result?.forEach(findSelected)
    return ignoreAllAndNone && ret.length === result.length ? [] : ret
  }, [schema]);

  const handleReloadClick = React.useCallback(() => {
    handleReload();
    onReload?.();
  }, [handleReload, onReload]);

  const handleFiltersClear = React.useCallback(() => {
    clearFiltersFn.current?.();
    onFiltersChange?.([]);
  }, [clearFiltersFn, onFiltersChange]);

  const handleDocumentationCreate = (closePopup) => { 
    closePopup();
    confirmationDialog.getConfirmation({ 
      content: (onOk, onClose) => <PdfDocRegLoader onOk={onOk} registry={registry} />, 
      type: "Dialog", 
    });
  };

  const toolbar = React.useMemo(() => {
    if (schema.data.fullCustomToolbar) return (
      <>
        {customToolbar}
      </>
    );

    return (
      <>
        {ToolButton(IconReload, handleReloadClick, true, null, "reload", null, <span>Перезагрузить</span>)}
        {/* <Divider key="-1" orientation="vertical" flexItem style={{ margin: "0 0.25em" }} /> */}
        {navigationToolbar}
        {/* {!!navigationToolbar && <Divider key="-3" orientation="vertical" flexItem style={{ margin: "0 0.25em" }} />} */}
        {/* <div>
          {!removeViewAllFieldsToolbutton &&
            ToolButton(
              AllFieldsIcon,
              handleAllFieldsView,
              selectedRows.length === 1,
              null,
              "all_fields",
              null,
              <Trans id="table.all_fields" />
            )}
        </div> */}
        {/* {!removeViewAllFieldsToolbutton && (
          <Divider key="-2" orientation="vertical" flexItem style={{ margin: "0 0.25em" }} />
        )} */}
        {customToolbar}
        {/* {schema.data.toolbar?.length > 0 && (
          <Divider key="1" orientation="vertical" flexItem style={{ margin: "0 0.25em" }} />
        )} */}
        {ToolButton(
          IconFilterRemove,
          handleFiltersClear,
          true,
          null,
          "reset_filters",
          null,
          <span>Сбросить фильтры</span>
        )}
      </>
    )
  }, [customToolbar, handleFiltersClear, handleReloadClick, navigationToolbar, schema]);

  const showToolbar = React.useMemo(() => {
    if (!schema || !schema.data) return true;
    return !schema.data.fullCustomToolbar || schema.data.toolbar.length !== 0;
  }, [schema]);

  const foundWidget = registry?.widget?.find((widget) => widget.widget_type === "grid");
  const isCreateDocButtonVisible = foundWidget?.operation.find(({ action }) => action === "document_registry");

  return (
    <div
      ref={tableRef}
      style={{
        flexGrow: 1,
        display: "flex",
        maxWidth: "100%",
        flexDirection: "column",
      }}
    >
      {showToolbar && (
        <Toolbar>
          <ScrollableContainer style={{ maxWidth: "unset", overflow: "hidden" }}>
            <ButtonGroup className="toolbar-container" removeTopBorder={removeTopBorder} variant="outlined" size="small">
              {toolbar}
            </ButtonGroup>
          </ScrollableContainer>
          <ButtonGroup>
            {customToolbarRightSideContent && customToolbarRightSideContent()}
            {tableSettings && (
              <PopupState variant="popover" popupId="table-popup-menu">
                {(popupState) => (
                  <>
                    {ToolButton(
                      IconDots,
                      popupState.open,
                      true,
                      <span>Управление таблицей</span>,
                      "table_settings",
                      null
                    )}
                    <Menu
                      {...bindMenu(popupState)}
                      autoFocus={false}
                      style={theme.typography.text}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <StyledMenuItem
                        value={0}
                        onClick={() => {popupState.close(); dispatch({ type: "SET_HIDDEN_COLUMNS", value: {
                          trDims,
                          onChange: onHiddenColumnsChange
                        }})}}
                      >
                        <IconColumns />
                        <div>Колонки</div>
                      </StyledMenuItem>
                      <StyledMenuItem
                        value={0}
                        onClick={() => {popupState.close(); clearSortingFn.current?.()}}
                      >
                        <IconSort/>
                        <div>Сбросить сортировку</div>
                      </StyledMenuItem>
                      <StyledMenuItem
                        value={0}
                        onClick={() => {popupState.close(); clearFilters()}}
                      >
                        <IconFilterRemove style={{ width: "16px", height: "16px", color: "white" }} />
                        <div>Сбросить фильтры</div>
                      </StyledMenuItem>
                      {isCreateDocButtonVisible && 
                        <StyledMenuItem
                          value={0}
                          onClick={() => handleDocumentationCreate(popupState.close)}
                        >
                          <IconDownload/>
                          <div>Создать документацию</div>
                        </StyledMenuItem>
                      }
                    </Menu>
                  </>
                )}
              </PopupState>
            )}
          </ButtonGroup>
        </Toolbar>
      )}
      {customContent && customContent()}
      <DataTableView
        trDims={trDims}
        onEditorOk={onEditCell}
        onDropHiddenColumn={onDropHiddenColumn}
        onColumnDrop={onColumnDrop}
        filtersFn={filtersFn}
        dropFn={dropFn}
        reloadFn={reloadFn}
        clearSortingFn={clearSortingFn}
        setRowsFn={setRowsFn}
        pushDataFn={pushDataFn}
        removeDataFn={removeDataFn}
        customSortOrder={customSortOrder}
        customOrderChange={customOrderChange}
        idInFilters={idInFilters}
        nestedDataLoad={handleExpandRowDataLoad}
        recalculateFn={recalculateFn}
        hiddenColumnDrag={hiddenColumnDrag}
        tags={tags}
        getSelected={getSelected || customGetSelected}
        onRowClick={onRowClick}
        cantEditCell={cantEditCell}
        onTagsChange={onTagsChange}
        onOrderChange={onOrderChange}
        onFiltersChange={onFiltersChange}
        clearFiltersFn={clearFiltersFn}
        onSelectedRowsChanged={handleSelectedRowsChanged}
        singleRowSelect={schema.data.singleRowSelect}
        reloadOnFiltersChange={reloadOnFiltersChange}
        disableMoveFiltersButton
        disableTableColumnHeaderIcons
        disableSearchAndReplaceButton
        dataItem={tableCurrent}
        api={tableApi}
      />
      {(itemsCounter || selectedCounter) && (
        <InformationGroup variant="outlined" size="small">
          {itemsCounter && (
            <div style={{ padding: "0 1rem", display: "flex", alignItems: "center", gap: "0.5rem" }}>
              <span>Количество элементов:</span>
              {elementsCountLoading ? <CircularProgress color="primary" size="16px" /> : typeof elementsCount === "number" ? parseNumber(elementsCount) : "неизвестно"}
            </div>
          )}
          {selectedCounter && (
            <div style={{ padding: "0 1rem", display: "flex", alignItems: "center", gap: "0.5rem" }}>
              <span>Выбрано элементов:</span>
              <span>{selectedRows.length}</span>
            </div>
          )}
        </InformationGroup>
      )}
    </div>
  );
}

const Toolbar = styled("div")`
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  overflow: hidden;
  gap: 8px;
`;

const StyledMenuItem = styled(MenuItem)`
  background: transparent;
  transition: background 0.15s ease-in-out;
  font-size: 14px;
  color: #42474D;
  display: flex;
  gap: 8px;

  svg {
    fill: #7B868C;
    transition: fill 0.15s ease-in-out;
  }

  &:hover {
    background: #EDF1F2;
    svg {
      fill: #42474D;
    }
  }
`;

const ButtonGroup = styled("div")`
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 0;
  border: 0;
  background: white;
  min-height: 56px;
  height: 56px;
  border-top: ${({ removeTopBorder }) => removeTopBorder ? "0 !important" : "inhirit"};

  & .toolbutton {
    gap: 0.4em;
    font-size: 13px;
    font-weight: 500;
    color: #1C458A;
    padding: 0 !important;
    width: 32px !important;
    height: 32px !important;
  }
  
  & .toolbutton svg {
    transition: fill 250ms cubic-bezier(0.4, 0, 0.2, 1);
    margin: 0;
    fill: #42474D !important;

    &[fill] {
      fill: #42474D !important;
    } 
    
    *[fill] {
      fill: #42474D !important;
    }
    
    *[fill="white"] {
      fill: white !important;
    }
  
    *[stroke] {
      stroke: #42474D !important;
    }
    
    rect {
      fill: #42474D !important;
    }
  }

  & .toolbutton-active {
    color: #0071e3;
    svg {
      fill: #0071e3 !important;

      &[fill] {
        fill: #0071e3 !important;
      } 

      *[fill] {
        fill: #0071e3 !important;
      }

      *[fill="white"] {
        fill: white !important;
      }
    
      *[stroke] {
        stroke: #0071e3 !important;
      }
      
      rect {
        fill: #0071e3 !important;
      }
    }
  }

  & .toolbutton:disabled {
    color: #DCDEE8;
    svg {
      fill: #DCDEE8 !important;

      &[fill] {
        fill: #DCDEE8 !important;
      } 

      *[fill] {
        fill: #DCDEE8 !important;
      }

      *[fill="white"] {
        fill: white !important;
      }
    
      *[stroke] {
        stroke: #DCDEE8 !important;
      }
      
      rect {
        fill: #DCDEE8 !important;
      }
    }
  }
`;

const InformationGroup = styled(StyledButtonGroup)`
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-left: 0 !important;
  border-right: 0 !important;
  padding: 0.5em !important;
  background: white !important;
`;

export default React.memo(React.forwardRef(TableComponent));
