import React from "react";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";

import TableEditor, { TOOLBAR_DISABLE_CASES } from "shared/ui/TableEditor";
import { ActionDialog } from "shared/ui";

import { CircularProgress } from "@mui/material";
import { ReactComponent as FileUploadIcon } from "./icons/upload.svg";
import { ReactComponent as FileAddIcon } from "./icons/add.svg";
import { ReactComponent as FileRemoveIcon } from "./icons/remove.svg";

import FirstStepUploadModal from "./FirstStepUploadModal";

const columns = [
  ["type", "Тип объекта", (value) => <div>{value.name}</div>],
  ["code", "Название объекта", (value) => <div>{value.label}</div>],
  ["filename", "Файл для загрузки"],
].map(([code, name, custom]) => ({
  id: code,
  styles: {
    minWidth: 170,
  },
  properties: {
    title: name,
    name: code,
    axis: "data",
    position: 0,
    sortOrder: "none",
    filter: [],
    textFilter: {},
    type: "Memo",
    isEditable: false,
    customCellRenderer: custom,
  },
}));

function FirstStep({ onClose, title, state, opened, onFilesUploaded }) {
  const id = React.useRef(0);
  const inputRef = React.useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  
  const api = useSelector((state) => state.API);

  const [files, setFiles] = React.useState([]);

  const [uploadModalOpen, setUploadModalOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const removeFiles = React.useCallback(({ rows }) => setFiles((files) => files.filter(({ id }) => !rows.includes(id))), []);

  const sendFiles = React.useCallback(async () => {
    setLoading(true);

    const result = await Promise.allSettled(
      files.map((fileData) => {
        const payload = new FormData();
        payload.append("token", api.token);
        payload.append("is_ui", true);
        if (fileData.code) payload.append("code", fileData.code.code);
        if (fileData.type) payload.append("type", fileData.type.id);
        
        if (typeof state?.params === "object") {
          Object.keys(state.params).forEach((key) => payload.append(key, state.params[key]));
        }
        
        payload.append("file", fileData.file);
        return fetch(process.env.REACT_APP_API + "/file/upload", { method: "POST", body: payload }).then((res) => res.json());
      })
    );

    const successFiles = result.filter((promise) => promise.status === "fulfilled");
    if (successFiles.length === 0) enqueueSnackbar("Ошибка загрузки", { variant: "error" });

    onFilesUploaded(
      successFiles
        .filter(({ value }) => typeof value === "object" && value !== null && !value.error && value.filename)
        .map(({ value }) => value.filename)
    );
    setFiles([]);
    setLoading(false);
  }, [api, state, enqueueSnackbar, files, onFilesUploaded]);

  const onFileAdd = React.useCallback((file) => setFiles((files) => [...files, { ...file, id: id.current++ }]), []);

  const handleFileAdd = React.useCallback(() => {
    if (state?.withoutForm) {
      const input = inputRef.current;
      input.value = "";
      input.click();
      return;
    }

    setUploadModalOpen(true);
  }, [state]);
  
  const handleFileSelected = React.useCallback((event) => {
    const file = event.target.files[0];
    if (!file) return;
    onFileAdd({ filename: file.name, file });
  }, [onFileAdd]);

  const schema = React.useMemo(
    () => ({
      value: "files",
      type: "Table",
      data: {
        id: "files-table",
        trDims: columns,
        fullCustomToolbar: true,
        selectedCounter: true,
        toolbar: [
          [FileAddIcon, handleFileAdd, true, "Добавить файл", null, null, "Добавить"],
          [FileUploadIcon, sendFiles, files.length > 0, "Загрузить файлы", null, null, "Загрузить"],
          [FileRemoveIcon, removeFiles, TOOLBAR_DISABLE_CASES.more_than_zero, "Удалить файлы", null, null, "Удалить"],
        ],
      },
    }),
    [files, removeFiles, handleFileAdd, sendFiles]
  );

  if (loading)
    return (
      <ActionDialog onClose={onClose} open={opened} title={title}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "1em" }}>
          <CircularProgress color="primary" />
        </div>
      </ActionDialog>
    );

  return (
    <ActionDialog onClose={onClose} open={opened} minHeight="60vh" title={title}>
      <TableContainer>
        <TableEditor tableSettings={false} removeTopBorder rerenderDeps={[files]} customTableDataGetter={() => files} schema={schema} />
      </TableContainer>
      <input
        ref={inputRef}
        type="file"
        onChange={handleFileSelected}
        style={{ display: "none" }}
        accept="application/json, text/csv, text/xml, application/vnd.ms-excel"
      />
      <FirstStepUploadModal opened={uploadModalOpen} onClose={() => setUploadModalOpen(false)} onFileAdd={onFileAdd} />
    </ActionDialog>
  );
}

const TableContainer = styled("div")`
  flex: 1;
  display: flex;

  .toolbar-container .toolbutton svg {
    width: 16px !important;
    height: 16px !important;
  }
`;

export default React.memo(FirstStep);
