import React from "react";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";

import { fSelect, fText } from "shared/ui/Editors";
import { TooltipR } from "shared/ui/ToolBar";
import { ActionDialog } from "shared/ui";

import { IconButton, Button, InputAdornment, CircularProgress } from "@mui/material";

import FileUploadIcon from "@mui/icons-material/CloudUploadOutlined";

const TYPE_FIELD_OPTIONS = [
  { id: "timeseries", name: "Набор данных" },
  { id: "reference", name: "Справочник" },
  { id: "parent_child_classifier", name: "Классификатор родитель-дитя" },
];

const BASE_FILE_DATA = {
  code: null,
  filename: null,
  type: TYPE_FIELD_OPTIONS[0],
};

const BASE_INPUT_PROPS = {
  disabled: false,
  fullWidth: true,
  style: "compact",
};

function FirstStepUploadModal({ opened, onClose, onFileAdd }) {
  const api = useSelector((state) => state.API);
  const { enqueueSnackbar } = useSnackbar();

  const [references, setReferences] = React.useState({});
  const [initialized, setInitialized] = React.useState(false);
  const [fileData, setFileData] = React.useState(BASE_FILE_DATA);

  React.useEffect(() => {
    if (!api) return;
    api.send("registries/data/allow_upload").then(res => {
      if (typeof res !== "object" || res === null || res.error) return;
      setInitialized(true);
      setReferences(res);
    });
  }, [api, enqueueSnackbar]);

  const isFilesReadyToRequest = React.useMemo(() => fileData.code !== null && fileData.filename !== null, [fileData])

  const handleChange = React.useCallback(
    (key, value) => setFileData(file => {
      const newFile = { ...file };
      newFile[key] = value;
      if (key === "type") newFile.code = null;
      return newFile;
    }),
    []
  );

  const sendFile = React.useCallback(() => {
    onFileAdd(fileData);
    setFileData(BASE_FILE_DATA)
    onClose();
  }, [fileData, onClose, onFileAdd]);

  const handleFileSelect = React.useCallback(() => {
    const input = document.getElementById("dwh-upload-file-input");
    input.value = "";
    input.click();
  }, []);

  const handleFileSelected = React.useCallback((event) => {
    const file = event.target.files[0];
    if (!file) return;
    setFileData(fileData => {
      const newFile = { ...fileData };
      newFile.filename = file.name;
      newFile.file = file;
      return newFile;
    })
  }, []);

  const inputProps = {
    ...BASE_INPUT_PROPS,
    onChange: (key, __, value) => handleChange(key, value),
    value: fileData,
  };

  if (!opened) return null;

  if (!initialized) return (
    <ActionDialog onClose={onClose} open={opened} title="Добавить файл">
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "1em" }}>
        <CircularProgress color="primary" />
      </div>
    </ActionDialog>
  )

  return (
    <ActionDialog onClose={onClose} open={opened} title="Добавить файл">
      {fSelect(inputProps, "Тип объекта", "type", null, { select: TYPE_FIELD_OPTIONS, disableClean: true })}
      {fSelect(inputProps, "Название объекта", "code", null, { 
        select: references[fileData.type?.id] || [], 
        optionNameField: "label", 
        optionIdField: "code", 
        disableClean: true 
      })}
      {fText(inputProps, "Файл для загрузки", "filename", null, {
        type: "text",
        disabled: true,
        endInputAdornment: (
          <InputAdornment style={{ position: "absolute", right: 1 }} position="end">
            <TooltipR text="Выбрать файл">
              <IconButton style={{ borderRadius: 0, borderLeft: "1px solid #ededed" }} size="small" onClick={handleFileSelect}>
                <FileUploadIcon style={{ width: 22, height: 22 }} />
              </IconButton>
            </TooltipR>
          </InputAdornment>
        ),
      })}
      <div style={{ marginTop: "0.75em", display: "flex", gap: 8, justifyContent: "center" }}>
        <Button disabled={!isFilesReadyToRequest} color="primary" variant="contained" onClick={sendFile}>
          Добавить
        </Button>
      </div>
      <input
        id="dwh-upload-file-input"
        type="file"
        onChange={handleFileSelected}
        style={{ display: "none" }}
        accept="application/json, text/csv, text/xml, application/vnd.ms-excel"
      />
    </ActionDialog>
  );
}

export default React.memo(FirstStepUploadModal);
