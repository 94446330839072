/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { observer } from "mobx-react-lite";

import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";

import { ReactComponent as IconClose } from "@mdi/svg/svg/close.svg";

import { Icon, TooltipR } from "shared/ui/ToolBar";
import { Tab } from "shared/ui/TabBar";

import CardView from "components/itemView/CardView";
import HyperGrid from "components/HyperGrid";
import Registry from "components/itemView/Registry";
import Registries from "components/itemView/Registries";
import Upload from "components/itemView/dataBundles/Upload";
import Download from "components/itemView/dataBundles/Download";

import Editor from "shared/ui/Editor";

import { Typography } from "@mui/material";
import styled from "styled-components";

import Dashboard from "components/itemView/Dashboard";
import Home from "components/itemView/Home";
import AssociatorTable from "components/itemView/Registry/AssociatorTable";
import SurveyView from "components/itemView/Registry/SurveyView";
import { QuizStorage } from "components/Survey/storages/QuizStorage";

import ScrollableContainer from "./ScrollableContainer";

const useStyles = makeStyles({
  tab: {
    "&:hover $tabLabel $closeIndicator": {
      visibility: "visible",
    },
    "&.Mui-selected": {
      borderBottom: "2px solid #E4E4E4 #0071e3",
    },
  },
  tabLabel: {
    display: "flex",
    overflow: "hidden",
    maxWidth: "100%",
    gap: 8,
    "& svg": {
      fill: "#7B868C",
    },
  },
  closeIndicator: {
    visibility: "hidden",
  },
  closeIndicatorCurrent: {
    visibility: "visible",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

export const ComponentView = (item, props, app) => {
  const pageProps = { item, ...(props || {}) };

  switch (item.current?.type) {
    case "Query":
      return <Editor {...pageProps} />;
    case "Chart":
    case "Map":
    case "Component":
      return <HyperGrid {...pageProps} />;

    case "bundles-upload":
      return <Upload {...pageProps} />;
    case "bundles-download":
      return <Download {...pageProps} />;

    case "home":
      return <Home />;

    case "associator":
      return (
        <AssociatorTable
          actionCode={item.current.actionCode}
          indicatorCode={item.current.indicatorCode}
          registryCode={item.current.registryCode}
        />
      );
    case "registry":
      return (
        <Registry
          mainTable
          filters={item.current.filter}
          fieldFilters={item.current.fieldFilter}
          apiPayload={item.current.apiPayload}
          code={item.current.registry || item.current.id}
        />
      );
    case "registries":
      return <Registries code={item.current.registry || item.current.id} />;
    case "survey":
      return <SurveyView code={item.current.code} registry={item.current.registry} />;
    case "url":
    case "bi_project":
      return <Dashboard link={item.current.properties.target} />;
    case "dashboard":
      return <Dashboard link={item.current.id} />;

    case "folder":
      return <CardView {...pageProps} />;

    default:
      return <CardView {...pageProps} />;
  }
};

export const TabbedView = ({ pages, setPages, ...props }) => {
  const [currentPage, setCurrentPage] = useState("tr");
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const closePage = React.useCallback(
    async ({ detail }) => {
      if (!detail) return;
      setPages(pages.filter(({ uniquePageId }) => uniquePageId !== detail));
    },
    [pages, setPages]
  );

  useEffect(() => {
    window.addEventListener("CLOSE_PAGE", closePage);
    return () => window.removeEventListener("CLOSE_PAGE", closePage);
  }, [closePage]);

  const openFirstPage = React.useCallback(() => {
    if (pages.length === 0) return;
    const firstPage = pages[0].uniquePageId;
    setCurrentPage(firstPage);
  }, [pages]);

  useEffect(() => {
    window.addEventListener("OPEN_FIRST_PAGE", openFirstPage);
    return () => window.removeEventListener("OPEN_FIRST_PAGE", openFirstPage);
  }, [openFirstPage]);

  const clearPages = React.useCallback(async () => {
    setPages([]);
    props.setCurrent(undefined);
    setCurrentPage(-1);
  }, [setPages, props.setCurrent]);

  useEffect(() => {
    window.addEventListener("CLOSE_ALL_PAGES", clearPages);
    return () => window.removeEventListener("CLOSE_ALL_PAGES", clearPages);
  }, [clearPages]);

  useEffect(() => {}, [props.api]);

  useEffect(() => {
    const { itemChanged } = props;
    if (!itemChanged) return;

    const { id, type } = itemChanged;
    const ndx = pages.findIndex((i) => i.item.current.id === id);

    switch (type) {
      case "item_delete":
        if (ndx === -1) break;

        if (pages[ndx].uniquePageId === currentPage) {
          const newCurrentIndex = ndx > 0 ? ndx - 1 : ndx + 1;
          setCurrentPage(newCurrentIndex < pages.length ? pages[newCurrentIndex].uniquePageId : null);
        }

        pages.splice(ndx, 1);
        setPages([...pages]);
        break;

      case "item_update":
        break;

      default:
        break;
    }
  }, [props.itemChanged, pages, currentPage, setCurrentPage, setPages]);

  useEffect(() => {
    const current = props.current;
    if (current?.current) {
      const key = current?.current.type === "osm_node" ? "osm_node" : current?.current.id;

      const p = [...pages];
      const found = p.findIndex((i) => i.uniquePageId === key);
      if (found !== -1) {
        p[found].item = current;
      }
      found === -1 && p.push({ item: current, uniquePageId: key });
      setPages(p);
      setCurrentPage(key);
    }
  }, [props.current]);

  useEffect(() => {}, [pages]);

  useEffect(() => {
    if (currentPage === -1) {
      // закрыли всё
      props.setCurrent(undefined);
      return;
    }

    const ndx = pages.findIndex((p) => p.uniquePageId === currentPage);
    if (ndx === -1) return;
    pages[ndx]?.item?.current && props.setCurrent(pages[ndx].item);
  }, [currentPage]);

  const handleChange = (event, id) => {
    id = Math.min(id, pages.length - 1);
    setCurrentPage(pages[id].uniquePageId);
  };

  const handleCloseTab = (id, event) => {
    event.stopPropagation();
    event.preventDefault();
    const p = [...pages];
    const closeCurrent = p[id].uniquePageId === currentPage;
    if (p[id]?.uniquePageId?.includes("-survey") && !QuizStorage.saveError) enqueueSnackbar("Анекта сохранена", { variant: "success" });
    p.splice(id, 1);
    closeCurrent && setCurrentPage(p[id - 1]?.uniquePageId || p[id]?.uniquePageId || -1);
    setPages(p);
  };

  const workAreaTabStyle = {
    overflow: "none",
    margin: 0,
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    position: "relative",
  };
  const currentPageNdx = pages.findIndex((p) => p.uniquePageId === currentPage);

  const Page = (page, hidden) => ComponentView(page.item, {}, props.app);

  if (pages.length === 0) return null;

  return (
    <div style={{ display: "flex", width: "100%", flex: 1, zIndex: 11 }}>
      <div
        style={{
          width: "0",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ display: "flex", height: 40, alignItems: "center", width: "100%", background: "#F7F9FA" }}>
          <ScrollableContainer>
            {pages.map((page, ndx) => (
              <TabContainer onClick={() => handleChange(null, ndx)} key={ndx}>
                <StyledTab
                  className={classes.tab}
                  selected={currentPageNdx === ndx}
                  label={
                    <div className={classes.tabLabel} title={page.item.current?.properties?.title}>
                      <Typography
                        style={{
                          display: "block",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          textAlign: "start",
                          fontSize: 13,
                          fontWeight: 500,
                          lineHeight: "16px",
                          color: currentPageNdx === ndx ? "#42474D" : "#7B868C",
                        }}
                      >
                        {page.item.current?.properties?.title}
                      </Typography>
                      <TooltipR text="Закрыть">
                        <div
                          className={classes.closeIndicatorCurrent}
                          onClick={handleCloseTab.bind(null, ndx)}
                          style={{ width: 16, height: 16 }}
                        >
                          {Icon("action", IconClose, 16)}
                        </div>
                      </TooltipR>
                    </div>
                  }
                />
              </TabContainer>
            ))}
            <TabPlaceholder />
          </ScrollableContainer>
        </div>
        {pages.map((page, ndx) => (
          <Paper key={page.uniquePageId} hidden={currentPageNdx !== ndx} square elevation={0} style={workAreaTabStyle}>
            {Page(page, currentPageNdx !== ndx)}
          </Paper>
        ))}
      </div>
    </div>
  );
};

const TabPlaceholder = styled("div")`
  flex: 1;
  border: 1px solid #dce3e5;
  border-left: 0;
  border-right: 0;
`;

const TabContainer = styled("div")`
  display: flex;
  min-width: max-content;
`;

const StyledTab = styled(Tab)`
  background: ${({ selected }) => (selected ? "white" : "#F7F9FA")};
  border-right: 1px solid #dce3e5;
  border-top: 1px solid #dce3e5 !important;
  border-bottom: 1px solid #dce3e5 !important;
  height: 40px !important;
  padding: calc(0.5em + 1px) 12px !important;
  opacity: 1 !important;

  &.Mui-selected {
    padding-top: 0.5em !important;
    border-top: 2px solid #176def !important;
    border-bottom: 1px solid transparent !important;
  }
`;

const mapStateToProps = function (state) {
  return {
    api: state.API,
    app: state.APP,
    pages: state.pages,
    db: state.databaseAPI,
    itemChanged: state.itemChanged,
    current: state.current,
    currentProps: state.currentProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPages: (pages) => dispatch({ type: "SET_PAGES", value: pages }),
    setCurrent: (node) => dispatch({ type: "SET_CURRENT", value: node }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(observer(TabbedView));
